const landingPageImageList = [`https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7875_-oa5fEriO.jpg?updatedAt=1700987166233`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7900_-jAljO6mrm.jpg?updatedAt=1700987170413`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PM_3_cover_mSgzMh22G.jpg?updatedAt=1706087425233`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/elegance_cover_pwAeKS3Ct.jpg?updatedAt=1703596248700`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06240_pvJnJLMjKK.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7949_n3f5ZMJi6o.jpg?updatedAt=1700987200272`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7933_iTY13XXyg.jpg?updatedAt=1700987199452`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7935_OO4aODHlB6.jpg?updatedAt=1700987200063`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7947_qAI-vkxuG.jpg?updatedAt=1700987199313`,
]

export default landingPageImageList