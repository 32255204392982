import React, { useEffect, useRef, useState } from 'react'
import { BiLogoInstagram } from "react-icons/bi"
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { IoIosClose } from "react-icons/io";
import { useMediaQuery } from 'react-responsive';
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';

import "./Gallery.css"

function Gallery({imageList, currentIndex}) {
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' })

  const boxRef = useRef(null);

  const [galleryRef, galleryinView] = useInView()
  const galleryAnim = useAnimation(); 

  const optmizedList = imageList.map((linkData)=>{
    let temp1 = linkData.substring(0,32)
    let temp2 = linkData.substring(42, linkData.length)
    let temp = temp1+"/tr:w-200"+temp2+" 400w,"+ temp1+"/tr:w-800"+temp2+" 800w,"+ temp1+"/tr:w-1200"+temp2+" 1200w"
    return {
      src: linkData,
      srcSet: temp
    }
  })

  const [viewFlag, setViewFlag] = useState(false)

  const [current, setCurrent] = useState((typeof currentIndex)==="undefined"? 0:currentIndex)
  
  /////////////////////////////////////////////////
  //////// Functions /////////////////////////////
  ////////////////////////////////////////////////

  const handleImgClick=(index)=>{
    setCurrent(index)
    setViewFlag(true)
    // boxRef.current.scrollIntoView({ behavior: 'smooth' })
    window.scrollTo({top: boxRef.current.offsetTop-150, behavior: "instant"})
  }

  const nextSlide=()=>{
    setCurrent(current === imageList.length -1 ? 0: current+1)
  }
  
  const prevSlide=()=>{
    setCurrent(current === 0 ? imageList.length-1: current-1)
  }

  /////////////////////////////////////////////////
  ///////////// Hooks /////////////////////////////
  ////////////////////////////////////////////////

  useEffect(()=>{
    if(galleryinView){
      galleryAnim.start("visible")
    }
  },[galleryinView])

  // useEffect(()=>{
  //   console.log(JSON.stringify(optmizedList,null,4))
  // },[imageList])

  return (
    <div className="" ref={boxRef} 
      style={{width:`100%`,height:`auto`, 
        // border:`5px solid red`
      }}
    >
    <motion.div className="section-grid-container"
        ref={galleryRef}
        variants={{
          hidden:{y:"20%", opacity:0},
          visible:{y:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
        }} 
        initial="hidden"
        animate={galleryAnim}

        style={{border:"0.5px solid var(--textColour)"}}
      > 

      {/* Socials  */}
      <div className='gallery-social-container'
        
      >
              <div className='gallery-social-header'>
              {/* <h3 > */}

                <a href="https://instagram.com/skprecious_banquet" target="_blank" rel="noreferrer" 
                  aria-label='Check us out on Instagram'
                  > 
                  <BiLogoInstagram className='gallery-insta-icon' /> 
                </a>

              {/* </h3> */}
              <h3>
                Check us out on Instagram!
              </h3>
              </div>


              {viewFlag && 
              <h3
                onClick={()=>setViewFlag(false)}
              >
                <IoIosClose />
              </h3>
              }
              
      </div>

      {/* Grid View */}
      {!viewFlag &&
      <div className="gallery-img-container gallery-grid">
          
          {optmizedList.map((imgData,index)=>{
            return (
            <div className="gallery-grid-img-box"
              key={index}
            >
              <img className="gallery-grid-img"
                src={imgData.src}
                srcSet={imgData.srcSet}
                sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
                alt="img"
                loading='lazy'
                onClick={()=>handleImgClick(index)}
              />
            </div>)
          })}

      </div>}

      {/* Slider View  */}
      {viewFlag &&
      <>        
          <div className="gallery-img-container">
              

              <div className="gallery-controll-btn">
                {/* <div className="decoration-half"></div> */}
                <h1>
                <AiOutlineLeft onClick={()=>prevSlide()} />
                </h1>


              </div>
            
              <div className='gallery-img-box'>
                  {/* <Suspense fallback={<div>Loading</div>}> */}

                    <img className='gallery-img'
                      src={optmizedList[current].src} 
                      srcSet={optmizedList[current].srcSet}
                      sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
                      alt="img"
                      loading='lazy' />
                  {/* </Suspense> */}
              
              </div>

                  

              <div className="gallery-controll-btn">
                <h1>
                <AiOutlineRight onClick={()=>nextSlide()}/>
                </h1>
              </div>
                
              
          </div>

          {!isSmallWindow &&
          <>
          <div></div>   
          <br />
          </>
        }
      </>
      }

      </motion.div>
      </div>
  )
}

export default Gallery