import React, { useEffect, useRef, useState } from 'react'
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import {BsArrowRight} from "react-icons/bs"
import "./Home.css"
import landingPageImageList from "../../components/Lists/LandingPageImageList"

import Gallery from '../../components/Gallery/Gallery';
import Form from '../../components/Form/Form'
import Loading from '../../components/Transition/Loading'
import Banner from '../../components/Banner/Banner'
import { useMediaQuery } from 'react-responsive';

function Home({passLoaderFlag}) {
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' })

  // const coverImg_1 = "images/hall-test.jpg"
  // const coverImg_1 = "https://ik.imagekit.io/qljqw3tvn/tr:w-1800/PreciousBanquet/PB_entrance_LKHtprh1PL.jpg"
  const coverImg_1 = "./images/cover-img-test.png"
  const hall_1 = "./images/SK_Precious.jpg"
  const hall_2 = "./images/preciousMoments_cover.jpeg"
  // const food_cover = "https://ik.imagekit.io/qljqw3tvn/PreciousBanquet/food-test_pWwwejOIXJ.jpg"
  const food_cover = "https://ik.imagekit.io/qljqw3tvn/PreciousBanquet/food_cover_4Xo_N4xQQ.jpg?updatedAt=1706430653874"

  // const gallery_1 = "images/gallery_1.jpg"

  // const aboutUsRef = useRef()
  const [aboutUsRef, aboutUsinView] = useInView()
  const aboutUsAnim = useAnimation();  

  const [about_0Ref, about_0inView] = useInView()
  const about_0Anim = useAnimation();  

  const [about_1Ref, about_1inView] = useInView()
  const about_1Anim = useAnimation();  

  const [about_2Ref, about_2inView] = useInView()
  const about_2Anim = useAnimation();
  
  const [about_3Ref, about_3inView] = useInView()
  const about_3Anim = useAnimation();

  const [about_4Ref, about_4inView] = useInView()
  const about_4Anim = useAnimation();

  const [skHallRef, skHallinView] = useInView()
  const skHallAnim = useAnimation();

  const [FandSRef, FandSinView] = useInView()
  const FandSAnim = useAnimation();

  const [menuRef, menuinView] = useInView()
  const menuAnim = useAnimation();

  // const [serviceRef, serviceinView] = useInView()
  // const serviceAnim = useAnimation();
  
  const [loadingFlag, setLoadingFlag] = useState(true)


  useEffect(()=>{
    passLoaderFlag(false)
    setTimeout(()=>{
      setLoadingFlag(false)
      passLoaderFlag(true)
    },3000)
  },[])

  ///////Animation Hook//////////////////
  useEffect(()=>{
    if(aboutUsinView){
      aboutUsAnim.start("visible")
    }
    if (about_0inView) {
      about_0Anim.start("visible")
    }
    if (about_1inView) {
      about_1Anim.start("visible")
    }
    if (about_2inView) {
      about_2Anim.start("visible")
    }
    if (about_3inView) {
      about_3Anim.start("visible")
    }
    if (about_4inView) {
      about_4Anim.start("visible")
    }if(skHallinView){
      skHallAnim.start("visible")
    }
    if (FandSinView) {
      FandSAnim.start("visible")
    }
    if (menuinView) {
      menuAnim.start("visible")
    }
    // if (serviceinView) {
    //   serviceAnim.start("visible")
    // }
  },[aboutUsinView, about_0inView, about_1inView, about_2inView,
    about_3inView, about_4inView, skHallinView, 
    FandSinView, menuinView])

  return (
    <>
    {loadingFlag ?
    <Loading />
    :
    
    
    <div className="content-container home-container">
      

        
        {/* <video autoPlay muted loop poster='./images/video-cover.png'>
          <source src='https://drive.google.com/uc?export=download&id=1ztiET7Rrm0eIwJQrDUjoZSGurI6v3mQy' 
            type='video/mp4' />
        </video> */}
      
      {/* Cover Image */}
      <motion.div className="full-img-container"
        initial={{opacity:0,y:"10%"}}
        animate={{opacity:1,y:0}}
        transition={{duration:0.6,ease:[0.6,0.01,0.05,0.95]}}
        style={{ objectFit:"contain"}}
      >
        <img 
          // style={{opacity:`90%`,padding:`4rem`}}
          src={coverImg_1} alt="" />
        
      </motion.div>

      {/* Heading */}
      <div className="banner-section">
        <Banner text={"Luxurious Locations For Your"} />
        {/* <Banner text={"For Your"}/> */}
        <Banner text={"Precious Moments."} />
      </div>

      <div className=""
        style={{width:"100%",height:"10vh"}}
      ></div>

      {/* About Us */}
      <div className="half-grid-container">

        <div className="full-row-container">
          <div className="big-text-container left-img-text"
            // ref={aboutUsRef}
          >
            <motion.h1
              variants={{
                hidden:{x:"-1rem"},
                visible:{x:0,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }} 
              initial="hidden"
              animate={aboutUsAnim}
            >About Us</motion.h1>
          </div>
        </div>

      <div>  
      <section className='body-section'
          ref={aboutUsRef}>
            <div>
            <span>2</span>
            <motion.h3
              ref={about_0Ref}
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={about_0Anim}
            >
              Luxurious Banquets
            </motion.h3>
            </div>
        </section>

        <section className='body-section'>
            <div>
            <span>7</span>
            <motion.h3
              ref={about_1Ref}
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={about_1Anim}
            >
              Experience Halls
            </motion.h3>
            </div>
        </section>

        <section className='body-section' ref={about_2Ref}>
          <div><span >30</span> 
            <motion.h3
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={about_2Anim}
            >
              Years of Experience and more
            </motion.h3>
          </div>
        </section>

        <section className='body-section' ref={about_3Ref}>
          <div><span>4000</span>
            <motion.h3
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={about_3Anim}
            >
            Weddings and Social Events
            </motion.h3> 
          </div>
        </section>

        <section className='body-section' ref={about_4Ref}>
          <div><span>200000</span> 
            <motion.h3
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={about_4Anim}
            >

              Happy Guests Served
            </motion.h3>
          </div>
        </section>
      </div>

      {/* <div 
        className={`${isSmallWindow? "full-row-container":"" } `}
        style={isSmallWindow?{gridColumn:"1/span 2"}:{}}
      > */}
        <section className='medium-text-section'>
          <p
            style={{fontSize:`1.2rem`}}
          >Welcome to Precious Banquets - Your Premier Banquet Hall Destination</p>
          <br />
          <p
            style={{fontSize:`1.2rem`}}
          >At Precious Banquets, we take pride in creating unforgettable moments for your special occasions. With over 30 years of expertise in hosting weddings, parties, and events, we aspire to be recognized among the world's best wedding venues. Our commitment to excellence extends beyond our beautiful banquet halls; it's ingrained in the personalized service we offer. From the moment you step through our doors, our experienced team ensures every detail is handled with precision, making your event truly special.</p>
          {/* <br />
          <p>Make your day your own with collaboration of your choices and skills of the experts at Precious Moments.</p> */}
        </section>
        
      {/* </div> */}
      
      </div>

      {/* Halls Section */}
      <motion.div className="section-grid-container" 
        ref={skHallRef}
        variants={{
          hidden:{y:"20%", opacity:0},
          visible:{y:0,opacity:1,transition:{duration:0.8, ease:[0.6,0.01,0.56,0.93]}}
        }} 
        initial="hidden"
        animate={skHallAnim}

        style={{border:"0.5px solid var(--textColour)"}}
        >

        {/* Hall 1 */}
        <div className="section-img-container left-img-container">
            
          <a href="/venues/sk-precious" aria-label='Know more about SK Precious Halls'>
            <img className='section-img' 
            src={hall_1} alt="" />

              <div className="section-img-text">
                EXPLORE
              </div>
          </a>
        </div>

          <section className='medium-text-section left-medium-text'>
            <h3>SK Precious Banquet Hall</h3>
            <br />
            <p style={{fontStyle:"italic"}}>A modern haven of elegance and style.</p> 
            <br />
            <p>Recently renovated, our halls boast a capacity for 700 guests, blending opulence with comfort. The rooftop, with open space and panoramic views, adds a unique touch to your special events.</p> 
            <p>At SK Precious, we redefine luxury gatherings, offering a contemporary venue where every detail is tailored to ensure your celebration is extraordinary and unforgettable.</p>
            {/* <p>Every Floor has a story worth exploring, worth sharing your precicous moments in.</p> */}
            {/* <p style={{fontStyle:"italic"}}>Luxury has many levels.</p> */}
            {/* <br />
            <p>About Hall 1 #4</p>
            <p>About Hall 1 #5</p> */}
          </section>
          
          {/* Hall 2 */}
          {isSmallWindow?
          <>
           <div className=""
            style={{width:"100%",height:"10vh"}}
            ></div>


          <div className="section-img-container right-img-container">
            <a href="/venues/precious-moments" rel="noreferrer">
            
              <img className='section-img' 
                src={hall_2} alt="" />

              <div className="section-img-text">
                EXPLORE
              </div>

            </a>
          </div>

          <section className={`medium-text-section 
                              ${!isSmallWindow?
                              "right-medium-text":"left-medium-text"}`}
          >
              <h3>Precious Moments Banquet Hall</h3>
            <p style={{fontStyle:"italic"}}>Where affordability meets sophistication.</p>
            <br />
            <p>With modern interiors, our halls, accommodating up to 300 guests, are designed for intimate gatherings. Backed by over 12 years of experienced management, we ensure seamless events.</p>
            <p>Ample parking, a newly elevated exterior, and contemporary interiors make Precious Moments Banquet the perfect setting for creating cherished memories in a stylish and budget-friendly environment.</p>
            {/* <br /> */}
          </section>
          <div className=""
            style={{width:"100%",height:"5vh"}}
          ></div>
          </>
          :
          <>
          
          <section className={`medium-text-section 
                              ${!isSmallWindow?
                              "right-medium-text":"left-medium-text"}`}
          >
            <h3>Precious Moments Banquet Hall</h3>
            <p style={{fontStyle:"italic"}}>Where affordability meets sophistication.</p>
            <br />
            <p>With modern interiors, our halls, accommodating up to 300 guests, are designed for intimate gatherings. Backed by over 12 years of experienced management, we ensure seamless events.</p>
            <p>Ample parking, a newly elevated exterior, and contemporary interiors make Precious Moments Banquet the perfect setting for creating cherished memories in a stylish and budget-friendly environment.</p>
            {/* <br /> */}
          </section>

        <div className="section-img-container right-img-container">
            <a href="/venues/precious-moments" rel="noreferrer">

              <img className='section-img' 
                src={hall_2} alt="" />

              <div className="section-img-text">
                EXPLORE
              </div>
            </a>
        </div>
        </>
        }

      </motion.div>

      <div className=""
            style={{width:"100%",height:"10vh"}}
            ></div>

      {/* Food and Services */}
      <div className="full-img-container">
        <img src={food_cover}
          className='blur-img' 
          style={{objectPosition:`center 90%`}}
        alt="" />
      </div>

      <div className="half-grid-container">
        
        <div className="full-row-container"
          style={{marginBottom:`0.5rem`}}
        >
          <div className="big-text-container left-img-text"
            ref={FandSRef}
          >
            <motion.h1
              variants={{
                hidden:{x:"-1rem"},
                visible:{x:0,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }} 
              initial="hidden"
              animate={FandSAnim}
            >Banquet & Taste</motion.h1>
          </div>
        </div>
        
        {/* Food */}
        {/* <div> */}
          <section className='medium-text-section right-medium-text'>
            <p>
              At Precious Banquets, we understand the significance of delicious cuisine in making your event truly memorable.
            </p>
            <br />
            <p>
              Our expert culinary team crafts a diverse menu with a perfect blend of flavors, ensuring a culinary experience that delights every palate. From mouthwatering appetizers to decadent desserts, our catering services are tailored to meet your specific preferences.
            </p>
            <br />
            <p>
              Experience the perfect fusion of taste and presentation, making your event a gastronomic delight in one of the delhi's best wedding venues.
            </p>

            {/* <p>That's why we offer an exhausting menu of food items for you to choose from.</p>
            <br />
            <p>Pick your own delicacy for your very own day. </p> */}
            {/* <br /> */}
          </section>
        {/* </div> */}

        <div className='header-fill-container'>
          {/* <section className='header-section'>
            <h3><span style={{color:"var(--templateBackground"}}>S</span></h3>
          </section> */}

          <section className="header-section foodmenu-header-section"
          ref={menuRef}>
            <motion.h3
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={menuAnim}
            >
              Choose Your Delicacy
            </motion.h3>

            <div className="header-icon-container">
              <a href="/food-menu" aria-label='Know more about Food Options and Menus'>
              <div className="header-icon-box">

                <img className='header-icon-bg-img' 
                  src='images/button-bg.svg' alt=""/>
                  <BsArrowRight className='header-icon'/>
                </div>
                </a>

            </div>
          
          </section>

          {/* <section className="header-section"
            style={{border:"none"}}
          >
            <h3><span style={{color:"var(--templateBackground"}}>S</span></h3>
          </section> */}
        </div>
        

        {/* Services */}
        {/* <div>
          <section className='header-section'>
            <h3><span style={{color:"var(--templateBackground"}}>S</span></h3>
          </section>

          <section className="header-section" ref={serviceRef}>
            <motion.h3
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={serviceAnim}
            >
              Services
              </motion.h3>

            <div className="header-icon-container">

              <div className="header-icon-box">
                <img className='header-icon-bg-img' src='images/button-bg.svg' alt=""/>
                <BsArrowRight className='header-icon'/>
              </div>
            
            </div>
          
          </section>

          <section className="header-section"
            style={{border:"none"}}
          >
            <h3><span style={{color:"var(--templateBackground"}}>S</span></h3>
          </section>
        </div> */}

        {/* <div>
        <section className='medium-text-section left-medium-text'
          style={{paddingTop:"2rem"}}
        >
            
            <p>More About Additional Services PB offers #1</p>
            <p>More About Additional Services PB offers #2</p>
            <br />
            <p>More About Additional Services PB offers #3</p>
            <br />
            <p>More About Additional Services PB offers #4</p>
            <p>More About Additional Services PB offers #5</p>
          </section>
        </div> */}
      
      </div>

      {/* <div className="gallery-controll-btn">
        <h1>
        Prev
        </h1>

        <div className="gallery-decoration-circle"></div>
      </div> */}
      {/* <div className="section-grid-container" 
        style={{border:"0.5px solid var(--textColour)"}}
      >          
        <div className="gallery-img-container">
            
            <div className='gallery-img-box'>
              
              <img className='gallery-img'
                src={gallery_1} alt="img" />
              
              <div className="gallery-controll-btn-container">
                

                <div className="gallery-controll-btn">
                  <h1>
                  Next
                  </h1>

                  <div className="gallery-decoration-circle"></div>
                </div>

              </div>
            
            </div>

            <div className="big-text-container left-img-text">
              <h1>Gallery</h1>

            </div>

        </div>

      </div> */}

      <Gallery imageList={landingPageImageList} currentIndex={0}/>

      <div className=""
        style={{height:`10vh`}}
      ></div>

      <Form />
    </div>
    
    }
    </>
  )
}

export default Home