import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import "./Navbar.css"


function Navbar({loaderFlag}) {
    const navigate = useNavigate();

    const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <div className='navbar-container'>

        {loaderFlag && 
        <div className="nav-btn-container">

            {!isSmallWindow &&
            <div className='brand-info-container'>
                <h3 className='brand-info'>
                    ESTD
                </h3>
                <div className="decoration-circle"></div>
            </div>}

            <div className="btn-container">
                
                {/* <div className="hover-underline-animation-btn nav-btn"
                    onClick={()=>navigate("/")}
                >
                    Home
                </div> */}

                {/* <div className="hover-underline-animation-btn nav-btn">
                    About Us
                </div> */}

                <a href="/venues">
                <div className="nav-btn"
                    // onClick={()=>navigate("/halls")}
                >   

                    Venues   
                    <div className="nav-decoration-circle"></div>
                </div>
                </a>

                <a href="/food-menu">
                <div className="nav-btn"
                    // onClick={()=>navigate("/food-and-services")}
                >

                    Food Menu
                    <div className="nav-decoration-circle"></div>
                </div>
                </a>

                <a href="/contact-us">
                <div className="nav-btn"
                    // onClick={()=>navigate("/contact-us")}
                >   

                    Contact Us
                    <div className="nav-decoration-circle"></div>
                </div>
                </a>
            </div>

            {!isSmallWindow &&
            <div className='brand-info-container'>
                <div className="decoration-circle"></div>

                <h3 className='brand-info'>
                    1992
                </h3>
            </div>}      
        
        </div>}

        <div className="brand-logo-container">
            <div className="decoration-circle"></div>
            <h1 onClick={()=>navigate("/")}>PRECIOUS BANQUETS</h1>
            <div className="decoration-circle"></div>
        </div>

    </div>
  )
}

export default Navbar