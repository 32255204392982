import React from 'react'
import { motion } from "framer-motion"
import "./Banner.css"

function Banner({text}) {
    const banner = {
        hidden:{
            opacity:0
        },
        show:
        {
            opacity:1,
            transition:{
                duration:0.6,
                staggerChildren:0.1,
                delayChildren:0.4,
            }
        }
    }

    const letterAnimation = {
        hidden:{
            y:"100px"
        },
        show:{
            y:0,
            transition:{
                // ease:[0.6,0.01,0.56,0.93],
                duration:1,
            }
        }
    }

    // initial={{y:"100%"}}
    // animate={{y:0}}
    // transition={{duration:0.8, ease:[0.6,0.01,0.56,0.93], delay:0.3}}
  return (
    <div className='banner-container'>
        <motion.h1
            initial={{y:"100%"}}
            animate={{y:0}}
            transition={{duration:0.8, ease:[0.6,0.01,0.56,0.93]}}
        >
            {text}
        </motion.h1>
    </div>
  )
}

export default Banner