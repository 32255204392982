import { useState,useRef, useEffect } from 'react'
import { AnimatePresence, motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import emailjs from "emailjs-com"
import "./Form.css"
import { useMediaQuery } from 'react-responsive';

function Form() {
    const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' })

    const email_Key = process.env.REACT_APP_EMAIL_KEY
    const service_ID = process.env.REACT_APP_EMAIL_SERVICE_ID
    const template_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID

    const form_img = "https://ik.imagekit.io/qljqw3tvn/PreciousBanquet/PB_card_FmkPWAz16.png"
    const paper_bg = "images/paper_texture_2.jpg"

    const ref = useRef();

    const [cardRef, cardinView] = useInView({threshold:0.5})
    const animation1 = useAnimation();  

    const [errorPopup, setErrorPopup] = useState(false)
    const [successPopup, setSuccessPopup] = useState(false)

    const [formData, setFormData] = useState({
        user_first_name:'',
        user_last_name:'',
        phone:'',
        user_email:'',
        eventDate:'',
        guest_Traffic:'',
        eventType:'',
        moreInfo:''
    })

    const {user_first_name, user_last_name, phone, user_email,eventDate, guest_Traffic} = formData
  
    /////////////////////////////////////////////////
    //////// Functions /////////////////////////////
    ////////////////////////////////////////////////

    const onChange = (e) => {
        setFormData((prevState)=>({
            ...prevState, 
            [e.target.name] : e.target.value,
        }))
        // console.log(`Entered onChange`)
    }

    const handleNumField = (e) => {
        const value = e.target.value.replace(/[^+-0123456789\s]/g, "");
        // const value = tempvalue.replace(/[]/g,)
        setFormData((prevState)=>({...prevState, [e.target.name]:value}));
      };

    const onSubmit = (e) => {
        e.preventDefault()
        
        if(user_first_name==='' || user_last_name==='' || phone==='' || eventDate===''
        || guest_Traffic==='' ){
            setErrorPopup(true)
            setTimeout(()=>{
                setErrorPopup(false)
              },2000)
        }else{

            const userData = {
                user_first_name, user_last_name, phone, eventDate, guest_Traffic
            }
            console.log(`userData: ${JSON.stringify(userData,null,4)}`)

            // setSuccessPopup(true)
            //     setTimeout(()=>{
            //         setSuccessPopup(false)
            //     },2000)

            emailjs.sendForm(service_ID,
                template_ID, 
                e.target,
                email_Key)
                .then(res=>{
                    // console.log(res)
                        setSuccessPopup(true)
                        setTimeout(()=>{
                            setSuccessPopup(false)
                        },4000)
                }).catch(err=> console.log(err))
            // emailjs.sendForm(service_ID,
            //     template_ID, 
            //     e.target,
            //     email_Key)
            //     .then(res=>{
            //         console.log(res)
            //             setSuccessPopup(true)
            //             setTimeout(()=>{
            //                 setSuccessPopup(false)
            //             },4000)
            //     }).catch(err=> console.log(err))
        }
    }

    /////////////////////////////////////////////////
    ///////////// Hooks /////////////////////////////
    ////////////////////////////////////////////////
    useEffect(()=>{
        if(cardinView){
            animation1.start("visible")
        }
    },[cardinView])

  return (
    <>

    <div className='form-container'>
        
        {/* Card Image */}
        <motion.div 
            className="form-card-img"
            ref={cardRef}
            variants={{
                hidden:{x:"-5%",y:"2%",rotate:"0"},
                visible:{x:"-20%",y:"-10%",rotate:"-2.5deg",transition:{duration:0.6,ease:[0.6,0.01,0.22,0.93]}}
            }} 
            initial="hidden" 
            animate={animation1}
        >
        
            <img src={form_img} alt="" />
            {/* </>
            } */}
        </motion.div>
        
        {/* Actual Form */}
        <form onSubmit={onSubmit}>
            
            <motion.div className="form-grid"
                variants={{
                    hidden:{x:"-5%",y:"2%"},
                    visible:{x:"0",y:"0",transition:{duration:0.6,ease:[.38,.16,.52,.76]}}
                }} 
                initial="hidden" 
                animate={animation1}
            >
              <div className="form-img">
                <img src={paper_bg} style={{opacity:0.8}} alt="" />
              </div>
                    
                <div className="form-group"
                    style={{gridColumn: "1 / span 2"}}
                >
                    <div className='form-header'>
                        Inquire
                    </div>
                </div>

                <div className="form-group">
                    {/* <label>Name</label> */}
                    <input type = "text" 
                        className="form-control" 
                        name = 'user_first_name'
                        id = 'user_first_name'
                        value = {user_first_name} 
                        placeholder= 'First Name *'
                        onChange = {onChange}
                        autoComplete="off"
                        // required
                        />
                </div>

                <div className="form-group">
                    {/* <label>Name</label> */}
                    <input type = "text" 
                        className="form-control" 
                        name = 'user_last_name'
                        id = 'user_last_name'
                        value = {user_last_name} 
                        placeholder= 'Last Name *'
                        onChange = {onChange}
                        autoComplete="off"
                        // required
                    />
                </div>


                    {/* <label>E-mail</label> */}
                {/* <div className="form-group" style={{gridColumn: `1 / span 2`}}>
                    <input type="email" 
                        className="form-control" 
                        name='user_email'
                        id='user_email' 
                        value={user_email} 
                        placeholder='E-mail'
                        onChange={onChange}
                        autoComplete="off"
                    />
                </div> */}

                <div className="form-group">
                    {/* <label>Phone</label> */}
                    <input type="text" 
                        className="form-control" 
                        name='phone'
                        id='phone' 
                        value={phone} 
                        placeholder='Phone Number *'
                        onChange={handleNumField}
                        // onKeyPress={(e) => !/[0-9,.,+, ]/.test(e.key) && e.preventDefault()}
                         />
                </div>

                <div className="form-group">
                    {/* <label>guest_Traffic</label> */}
                    <input type="text" 
                        className="form-control" 
                        name='guest_Traffic'
                        id='guest_Traffic' 
                        value={guest_Traffic} 
                        placeholder='Number of Guests (approx) *'
                        onChange={handleNumField}
                        autoComplete="off"
                        // required
                    />
                </div>

                <div className="form-group form-group-textarea">
                    {/* <label>Event Date</label> */}
                    <input type="text"
                        ref={ref}
                        onFocus={(e) => (ref.current.type = "date")}
                        onBlur={(e) => (ref.current.type = "date")}
                        className="form-control" 
                        name='eventDate'
                        id='eventDate' 
                        value={eventDate} 
                        placeholder='Event Date *'
                        onChange={onChange}
                        autoComplete="off"
                        // required
                    />
                </div>

                {/* <div></div> */}

                    <div className="form-group">
                        <button type="submit" className="submit-btn">
                            Submit
                        </button>
                    </div>
                <div className="alert-submit-box">


                    <AnimatePresence>
                        {errorPopup &&
                            <motion.div className="form-group fill-space"
                            initial={{opacity:0}} 
                            animate={{opacity:1}}
                            exit={{opacity:0}}
                            transition={{duration:0.8}}
                            >
                                <div className="error-alert">
                                    Please fill all the fields
                                </div>
                            </motion.div>
                        } 
                    </AnimatePresence>

                    <AnimatePresence>
                        {successPopup && 
                         <motion.div className="form-group fill-space"
                            initial={{opacity:0}} 
                            animate={{opacity:1}}
                            exit={{opacity:0}}
                            transition={{duration:0.8}}
                            > 
                                <div className="error-alert success-alert">
                                    We will get back to you soon!
                                </div>
                        </motion.div>
                        }
                    </AnimatePresence>

                </div>
            </motion.div>
            
            

        </form>
    </div>

    {/* <div className="footer-form-container"> */}
        
        <div 
          className="footer-form-container"
            //   initial={{opacity:0}}
            //   animate={{opacity:1}}
            //   transition={{duration:1, ease:[.6,.01,.56,.94]}}
        >
          <p>Discover the perfect venue for your special moments.</p> 
          <p>Book your date and let us turn your dreams into reality.</p>
          
        </div>
      
      {/* </div> */}
    </>
  )
}

export default Form