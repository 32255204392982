import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { BsArrowRight } from 'react-icons/bs'
import Form from '../../components/Form/Form'
import { useMediaQuery } from 'react-responsive';

function FoodAndService() {
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' })
  // const coverImg_1 = "https://ik.imagekit.io/qljqw3tvn/PreciousBanquet/PB_entrance_LKHtprh1PL.jpg"
  
  const vegGoldLink = "https://drive.google.com/file/d/1tT-wtiZ5FcsgiCN5Bqmz55uHPINnCRL8/view?usp=drive_link"
  const vegPlatLink = "https://drive.google.com/file/d/1JssSvtsy89AIAcNoudcK5rcN9G4MLoGG/view?usp=drive_link"
  const nonVegGoldLink = "https://drive.google.com/file/d/1UaUuvmRrnWyVuSPgiksFvgB_p51vaOVo/view?usp=drive_link"
  const nonVegPlatLink = "https://drive.google.com/file/d/1x0FO6Xo0jRVGpJGYNcVepRlLAG0JlUGb/view?usp=drive_link"

  const [vegGoldRef, vegGoldInView] = useInView()
  const vegGoldAnim = useAnimation();

  const [vegPlatRef, vegPlatInView] = useInView()
  const vegPlatAnim = useAnimation();

  const [nonVegGoldRef, nonVegGoldInView] = useInView()
  const nonVegGoldAnim = useAnimation();

  const [nonVegPlatRef, nonVegPlatInView] = useInView()
  const nonVegPlatAnim = useAnimation();


  useEffect(()=>{
    if (vegGoldInView) {
      vegGoldAnim.start("visible")
    }if (vegPlatInView) {
      vegPlatAnim.start("visible")
    }if (nonVegGoldInView) {
      nonVegGoldAnim.start("visible")
    }if (nonVegPlatInView) {
      nonVegPlatAnim.start("visible")
    }
  },[vegGoldInView,
    vegPlatInView,
    nonVegGoldInView,
    nonVegPlatInView])

  return (
    <div className="content-container home-container">
        {/* Heading */}
      <div className="banner-section">
        
        <motion.div className="banner-heading banner-heading-no-underline"
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1, ease:[.6,.01,.56,.94]}}
        >
          <h1>Food Menu</h1>
          
        </motion.div>
      
      </div>

      <div className="half-grid-container alt-grid-container-section">
          <section className='medium-text-section right-medium-text'>
            <p>Immerse yourself in the opulence of vegetarian delight with our Veg Gold menu. </p>
            <p>Versatile and perfect for any event, this menu features gourmet vegetarian dishes crafted with precision, adding a touch of sophistication to your celebration.</p>
            {/* <br />
            <p>Choose from our vast range of Vegetarian delicacies.</p> */}
            
          </section>
      
        {/* Right Col */}
        <div className='header-fill-container'>

          <section className="header-section foodmenu-header-section"
          ref={vegGoldRef}
          >
            <motion.h3
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={vegGoldAnim}
            >
              Veg Gold Menu
            </motion.h3>

            <div className="header-icon-container">
              
              <a href={vegGoldLink}
                target="_blank" rel="noreferrer"
                aria-label='Know more about Veg Gold Menu'
                >

              <div className="header-icon-box">
                <img className='header-icon-bg-img' 
                  src='images/button-bg.svg' alt=""/>
                  <BsArrowRight className='header-icon'/>

              </div>
              </a>

            </div>

            </section>
          </div>
        </div>

      <div className="half-grid-container alt-grid-container-section">
        {/* Next Item */}
        {isSmallWindow?
        <>
          <section className='medium-text-section left-medium-text'
          >
            
            <p>Elevate your wedding feast with our premium Veg Platinum menu.</p>
            <p>Dedicated to showcasing a larger variety of exquisite vegetarian delicacies, it promises an extravagant culinary journey that mirrors the grandeur of your special day.</p>
            <br />
            {/* <p> </p>
            <br />
            <p> </p> */}

          </section>

          {/* Right col */}
          <div className='header-fill-container'>

          <section className="header-section foodmenu-header-section" 
            ref={vegPlatRef}
          >
            <motion.h3
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={vegPlatAnim}
            >
              Veg Platinum Menu
              </motion.h3>

            <div className="header-icon-container">

              <a 
                href={vegPlatLink}
                target="_blank" rel="noreferrer"
                aria-label='Know more about Veg Platinum Menu'
              >
                <div className="header-icon-box">
                  <img className='header-icon-bg-img' src='images/button-bg.svg' alt=""/>
                  <BsArrowRight className='header-icon'/>
                </div>
              </a>
            
            </div>

          </section>

          </div> 
        </>
        :
        <>
        <div className='header-fill-container'>

          <section className="header-section foodmenu-header-section" 
            ref={vegPlatRef}
          >
            <motion.h3
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={vegPlatAnim}
            >
              Veg Platinum Menu
              </motion.h3>

            <div className="header-icon-container">

              <a 
                href={vegPlatLink}
                target="_blank" rel="noreferrer"
                aria-label='Know more about Veg Platinum Menu'
              >
                <div className="header-icon-box">
                  <img className='header-icon-bg-img' src='images/button-bg.svg' alt=""/>
                  <BsArrowRight className='header-icon'/>
                </div>
              </a>
            
            </div>
          
          </section>

          
        </div>
        
        {/* Right col */}
        <section className='medium-text-section left-medium-text'
        >
            
            <p>Elevate your wedding feast with our premium Veg Platinum menu.</p>
            <p>Dedicated to showcasing a larger variety of exquisite vegetarian delicacies, it promises an extravagant culinary journey that mirrors the grandeur of your special day.</p>
            {/* <br />
            <p> </p>
            <br />
            <p> </p> */}
          </section>

        </>
        }
      </div>

      <div className="half-grid-container alt-grid-container-section">

        {/* Next Item */}
 
          <section className='medium-text-section right-medium-text'>
            <p>Savour the richness of our Non-Veg Gold menu, a versatile selection designed for any event.</p>
            <p>From succulent starters to flavorful main courses, each dish is a testament to culinary excellence, adding a premium touch to your non-vegetarian feast.</p>
            {/* <br />
            <p>Choose from our vast range of Non-Vegetarian delicacies.</p> */}
          </section>

        {/* Right Col */}
        <div className='header-fill-container'>

          <section className="header-section foodmenu-header-section"
          ref={nonVegGoldRef}
          >
            <motion.h3
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={nonVegGoldAnim}
            >
              Non-Veg Gold Menu
            </motion.h3>

            <div className="header-icon-container">
              

              <a 
                href={nonVegGoldLink}
                target="_blank" rel="noreferrer"
                aria-label='Know more about Non Veg Gold Menu'
              >
              <div className="header-icon-box">

                <img className='header-icon-bg-img' 
                  src='images/button-bg.svg' alt=""/>
                <BsArrowRight className='header-icon'/>

              </div>
              </a>

            </div>
          </section>

          
        </div>
      </div>

        {/* Next Item */}
      <div className="half-grid-container alt-grid-container-section">
        {
          isSmallWindow?
          <>
          <section className='medium-text-section left-medium-text'>
              
              <p> For the most discerning palates, our Non-Veg Platinum menu is a premium selection dedicated to weddings and special occasions.</p>
              <p>Offering an extensive range of exquisite non-vegetarian dishes, this menu is designed to impress, reflecting the grandiosity befitting your celebration.</p>
              {/* <br />
              <p>Be spolit for choices with our Non-Vegeterian Platinum Menu.</p> */}
              
            </section>

          {/* Right col */}
          <div className='header-fill-container'>

            <section className="header-section foodmenu-header-section" 
              ref={nonVegPlatRef}
            >
              <motion.h3
                variants={{
                  hidden:{y:"100%", rotate:"8deg",opacity:0},
                  visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
                }}
                initial="hidden"
                animate={nonVegPlatAnim}
              >
                Non-Veg Platinum Menu
                </motion.h3>

              <div className="header-icon-container">

                <a 
                  href={nonVegPlatLink}
                  target="_blank" rel="noreferrer"
                  aria-label='Know more about Non Veg Platinum Menu'
                >
                  <div className="header-icon-box">
                    <img className='header-icon-bg-img' src='images/button-bg.svg' alt=""/>
                    <BsArrowRight className='header-icon'/>
                  </div>
                </a>
              
              </div>
          
          </section>

          
        </div>        
        </>
          :
          <>
          <div className='header-fill-container'>

          <section className="header-section foodmenu-header-section" 
            ref={nonVegPlatRef}
          >
            <motion.h3
              variants={{
                hidden:{y:"100%", rotate:"8deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={nonVegPlatAnim}
            >
              Non-Veg Platinum Menu
              </motion.h3>

            <div className="header-icon-container">

              <a 
                href={nonVegPlatLink}
                target="_blank" rel="noreferrer"
                aria-label='Know more about Non Veg Platinum Menu'
              >
                <div className="header-icon-box">
                  <img className='header-icon-bg-img' src='images/button-bg.svg' alt=""/>
                  <BsArrowRight className='header-icon'/>
                </div>
              </a>
            
            </div>
          
          </section>

          
        </div> 

        {/* Right col */}
        <div>
        <section className='medium-text-section left-medium-text'>
            
            <p> For the most discerning palates, our Non-Veg Platinum menu is a premium selection dedicated to weddings and special occasions.</p>
            <p>Offering an extensive range of exquisite non-vegetarian dishes, this menu is designed to impress, reflecting the grandiosity befitting your celebration.</p>
            {/* <br />
            <p>Be spolit for choices with our Non-Vegeterian Platinum Menu.</p> */}
          </section>
        </div>
        </>
      }
      </div>

      <div className=""
        style={{width:"100%",height:"20vh"}}
      ></div>

      <Form />

    </div>
  )
}

export default FoodAndService