const floorList= [{
    venueName:"SK Precious",
    venueSlug:"sk-precious",    
    hallName:"Radiance",
    slug:"radiance",
    coverImg:"https://ik.imagekit.io/qljqw3tvn/PreciousBanquet/radiance_cover_LuCMeGWI8f.jpeg?updatedAt=1707712624443",
    floor:"Ground Floor",
    floorNum:0,
    floorCardText:[`A sophisticated modern approach to small events. Radiance Hall is the ideal mix of indoor and outdoor catering with a view`],
    floorPoem:[`" In a venue of radiant grace,`,
                `Love's beauty finds its perfect place.`,
                `Amidst modern charm, we dance and sway,`,
                `In the radiance of this love-filled day. "`,
                `A sophisticated modern approach to small events. Radiance Hall is the ideal mix of indoor and outdoor catering with a view.`],
    ammenitiesList:[`100- 250 guests `,
                    `DJ with LED screens`,
                    `Outdoor covered catering`],
    imgLinkList:[`https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06259_p0VerAy2gw.jpg?updatedAt=1701265513270`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06255_QaphwKn02w.jpg?updatedAt=1701265513207`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06256_Af20a6M5bA.jpg?updatedAt=1701265513158`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06260_VxL-ex6FI.jpg?updatedAt=1701265512777`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06251_T23Nnfw9X.jpg?updatedAt=1701265512714`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06258_oFmE-s1Fc.jpg?updatedAt=1701265512688`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06252_vdLZ7I3OH.jpg?updatedAt=1701265512344`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06254_cjdiMMFt0R.jpg?updatedAt=1701265512345`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06253_4WfRhPx3TZ.jpg?updatedAt=1701265512286`, 
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06250_cv-w7eaQB.jpg?updatedAt=1701265512186`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06248_CdPNWeOZV.jpg?updatedAt=1701265509613`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06239_L-d1--USdh.jpg?updatedAt=1701265510111`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06242_XoxD8zx9Ow.jpg?updatedAt=1701265509557`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06247_kcRlvLGqgo.jpg?updatedAt=1701265509605`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06244_eBQVhtb3gm.jpg?updatedAt=1701265509493`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06224_0NFbbXtKQJ.jpg?updatedAt=1701265509599`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06241_BV9teX_KZr.jpg?updatedAt=1701265509470`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06249_mOG3vo4t9.jpg?updatedAt=1701265509466`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06240_pvJnJLMjKK.jpg?updatedAt=1701265509469`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Radiance/Copy%20of%20DSC06246_TZV37gWey.jpg?updatedAt=1701265509428`,
            ],

},{
    venueName:"SK Precious",
    venueSlug:"sk-precious",
    hallName:"Luminous",
    slug:"luminous",
    coverImg:"https://ik.imagekit.io/qljqw3tvn/tr:w-1800/PreciousBanquet/Luminous/0R5A7890_yvOP0jXtP.jpg",
    floor:"First Floor",
    floorNum:1,
    floorCardText:[`The perfect venue for intimate wedding ceremonies with illuminating interiors and rose gold accents.`],
    floorPoem:[`" In a venue luminous and bright,`,
                `Love shines like a guiding light.`,
                `Amidst modern charm, we celebrate,`,
                `Eternal love, it's no debate. "`],
    ammenitiesList:[`300- 700 guests`,
                    `Live Tandoors`,
                    `Luxurious furnishing`,
                    `Big Fat Indian Wedding stage`,],
    imgLinkList:[`https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7855_lJfzV08tW.jpg?updatedAt=1700987166939`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7920_JRlHOYE2Hk.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7900_-jAljO6mrm.jpg?updatedAt=1700987170413`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7890_yvOP0jXtP.jpg?updatedAt=1700987170203`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7850_yztoG_z560.jpg?updatedAt=1700987167069`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7846_FOvoL1Pzm.jpg?updatedAt=1700987167029`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7887_ldO6XyWgna.jpg?updatedAt=1700987167026`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7885_fMhSjbqWA.jpg?updatedAt=1700987166778`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7871_Pclec2hiV8.jpg?updatedAt=1700987166436`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7862_CU1VUWm8jg.jpg?updatedAt=1700987166352`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7886_SZK0aFAkMF.jpg?updatedAt=1700987166319`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7884_ZtKPX7C9h.jpg?updatedAt=1700987166012`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Luminous/0R5A7875_-oa5fEriO.jpg?updatedAt=1700987166233`,
            ],
},{
    venueName:"SK Precious",
    venueSlug:"sk-precious",
    hallName:"Elegance",
    slug:"elegance",
    coverImg:"https://ik.imagekit.io/qljqw3tvn/PreciousBanquet/elegance_cover_pwAeKS3Ct.jpg",
    floor:"Second Floor",
    floorNum:2,
    floorCardText:[`A modern take on events with minimally ambiguent interiors, royal chandeliers and floral stage.`],
    floorPoem:[`" In a venue of elegance, we unite,`,
                `Mirrors reflect love's gentle light.`,
                `Modern interiors, a dazzling embrace,`,
                `Elegance and love fill this space. "`],
    ammenitiesList:[`300- 700 guests`,
                    `Live Tandoors `,
                    `Luxurious furnishing`,
                    `Family lounge`,],
    imgLinkList:[`https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8848_rWeaMLWaeR.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8843_wVDuSP_4Gx.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8841_lcRxqztJHq.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8840_7euvlYkADY.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8846_UBX8y8rGa.jpg`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8835_lqZx32K5M.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8834_R0N6kxkh8.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8821_98iiDido4R.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8801_JKux4PzNbB.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8807_ETz4J-XPiX.jpg`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8828_zkJEiqkKD.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8833_AYCa50T62C.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8818_5ycazrvlV.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8811_03AwA0VFxM.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8809_K6asnfpNNT.jpg`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8810_yLke8Klzx.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Elegance/_96A8814_3NcTZrkNS8.jpg`,
                ],
},{
    venueName:"SK Precious",
    venueSlug:"sk-precious",
    hallName:"Gala",
    slug:"gala",
    coverImg:"https://ik.imagekit.io/qljqw3tvn/tr:w-1800/PreciousBanquet/Gala/0R5A7933_iTY13XXyg.jpg?updatedAt=1700987199452",
    floor:"Rooftop",
    floorNum:3,
    floorCardText:[`A semi-covered divine experience of earthiness and luxury. Gala is the perfect venue for sipping some cocktails and an eventful night out`],
    floorPoem:[`" At the grand gala, gowns and tuxedos gleam,`,
                `A night of splendor, like a dream.`,
                `Laughter, music, and memories made,`,
                `In this magnificent affair, joy won't fade. "`,
                `A semi-covered divine experience of earthiness and luxury. Gala is the perfect venue for sipping some cocktails and an eventful night out.`],
    ammenitiesList:[`150-500 guests`,
                    `Semi-covered`,
                    `Bar lounge`,
                    `DJ at "Another Level"`,],
    imgLinkList:[`https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7954_0_s8iiK-FU.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7944_sc4IgOLzQl.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7954_0_s8iiK-FU.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7951_-RRgcRAfNo.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7924_0q4lIZ9tpe.jpg`,
                
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7967_s-ywjDML_s.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7963_mocg_w9Eo0.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7971_Z-Bi3DXny.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7922_HzyDbktsWQ.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/Gala/0R5A7937_1z07EIVqJ.jpg`],
},{
    venueName:"Precious Moments",
    venueSlug:"precious-moments",
    hallName:"Hall 1",
    slug:"hall_1",
    coverImg:"https://ik.imagekit.io/qljqw3tvn/PreciousBanquet/PM_1_cover_UGZXOw7OT.jpg?",
    floor:"Ground Floor",
    floorNum:0,
    floorCardText:[``],
    floorPoem:[``],
    ammenitiesList:[],
    imgLinkList:[`https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1885_S26aTUgSFc.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1854_KGGwAsaH2_.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1852_Dpt--d3erw.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1879_75GGAJVsaI.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1880_wu4Ky7eug7.jpg`,
                
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1891_5Sm8-uiDmm.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1844_vD7hsdnsKW.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1846_MRLTKLIO_.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1909_qK41ckvNYS.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1904_5xeCqc3LY.jpg`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1908_CCC0bRHVc.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1906_vzURS4Hkp.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1901_hiuOv4rwp.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1900_mXU4PrQiZD.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1883_enB8IjFT3n.jpg`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1887__IMbuV5UrC.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1899_e06BCqLnap.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1898_cpwi5bq4Jc.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1892_WhFItI6HG8.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1893_Aqv-ceEI3.jpg`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1896_aHIzYgxXK.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1881_NjbDY97MZ.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_1/2X8A1897__IdTbxcVqr.jpg`,
            ],
},{
    venueName:"Precious Moments",
    venueSlug:"precious-moments",
    hallName:"Hall 2",
    slug:"hall_2",
    coverImg:"https://ik.imagekit.io/qljqw3tvn/tr:w-1800/PreciousBanquet/PM_2_cover_BvuVKyURe.jpg",
    floor:"First Floor",
    floorNum:1,
    floorCardText:[``],
    floorPoem:[``],
    ammenitiesList:[],
    imgLinkList:[`https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1792_sjzeztyCn.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1783_ocq3u4trP.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1780_jASa9F3pP.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1776_QAsr4h-cz.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1772_dByQZrcuD.jpg`,
                
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1744_xhngf2wT_.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1752_oll1MsqsY.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1768_QvQkdpO1uv.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1764_U2Y65Zf9B6.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1754_9umxz6UX5E.jpg`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1758_rIbHaDEnba.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1771_hsq_iMpggp.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1741_wVCRQJJNq.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1747_ycbE-ww5L-.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_2/2X8A1753_TL9uUy8yk.jpg`,
            ],
},{
    venueName:"Precious Moments",
    venueSlug:"precious-moments",
    hallName:"Hall 3",
    slug:"hall_3",
    coverImg:"https://ik.imagekit.io/qljqw3tvn/tr:w-1800/PreciousBanquet/PM_3_cover_mSgzMh22G.jpg",
    floor:"Second Floor",
    floorNum:2,
    floorCardText:[``],
    floorPoem:[``],
    ammenitiesList:[],
    imgLinkList:[`https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1803_xiiqM4zFi.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1801_hY40qJ3pS.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1830_AgZaJ-biC.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1827_EyKtHxt8z.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1805_WOrphRqpz.jpg`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1804_aRygJjGzu.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1829_5hSW9ruzvL.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1832_9cOz5Vw75i.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1819_BxA4COhcbU.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1833_GjY2-5p4e1.jpg`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1838_ZXt-_B3hJk.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1840_8XeS1dPnHe.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1839_CX9W0VOTeG.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1841_n7dPm4xMPD.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_3/2X8A1909_g4kpY40iN.jpg`,
            ],
},{
    venueName:"Precious Moments",
    venueSlug:"precious-moments",
    hallName:"Hall 4",
    slug:"hall_4",
    coverImg:"https://ik.imagekit.io/qljqw3tvn/tr:w-1800/PreciousBanquet/PM_4_cover_fsezGwzwv.jpg",
    floor:"Rooftop",
    floorNum:3,
    floorCardText:[``],
    floorPoem:[``],
    ammenitiesList:[],
    imgLinkList:[`https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1878_yIDMIRgLIo.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1877_fOh4zpYz2F.jpg`,            
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1873_KAaytzqqBr.jpg`,            
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1876_BDiavCfoAI.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1870_Q7m0UyB9R7.jpg`,
                
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1875_zt-lPJucv.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1872_JmGjxrTl_n.jpg`,            
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1871_sQm_v3U6a.jpg`,            
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1858_LPJRqjDGfY.jpg`,            
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1864_JL0gwRmhv.jpg`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1863_O2oJud2KIj.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1861_ZPfqw7T_k4.jpg`,            
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1859_NWBAf8NyDh.jpg`,            
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1868_VNAc263Jxv.jpg`,            
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1860_dtbU3Q_tA8.jpg`,

                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1865_lEyR-ojtF4.jpg`,
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1869_4y6ojoSwC.jpg`,            
                `https://ik.imagekit.io/qljqw3tvn/tr:w-1200/PreciousBanquet/PreciousMoments_4/2X8A1857_VgE1SDou4M.jpg`,
            ],
},]

export default floorList