import React from 'react'
import { BiLogoInstagram, BiLogoWhatsapp } from "react-icons/bi"
import "./Footer.css"
import ReactWhatsapp from 'react-whatsapp'
import { useMediaQuery } from 'react-responsive'

function Footer() {
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' })
  const logo = "https://ik.imagekit.io/qljqw3tvn/tr:w-0800/PreciousBanquet/PB_logo_weAfNb3mDz.png"
  const logoSrcSet = logo.substring(0,32)+"/tr:w-100"+logo.substring(42,logo.length)+" 400w,"+ logo.substring(0,32)+"/tr:w-400"+logo.substring(42,logo.length)+" 800w" 

  const addressLinkSKprecious = "https://maps.app.goo.gl/sjZHRJWitTEyMzo86"
  const addressLinkPreciousMoments = "https://maps.app.goo.gl/tn9qeCRwzz9M8p536"
  
  const emailLinkSKprecious = "mailto:skbyprecious@gmail.com"
  const emailLinkPreciousMoments = "mailto:preciousbanquet@gmail.com"

  const phoneLinkSKprecious = "tel:+919871443777"
  const phoneLinkPreciousMoments_1 = "tel:+919871502800"
  const phoneLinkPreciousMoments_2 = "tel:+919871221172"

  const whatsappNumber = "+919871443777"
  
  return (
    <div className='footer-container'
      // style={{backgroundColor:`red`}}
    >
      
      <div className="footer-brand-logo-container">
        <img className='footer-logo-img' 
          src={logo} 
          srcSet={logoSrcSet}
          sizes="(max-width: 400px) 200px, (max-width: 768px) 400px"
          alt="Precious Banquets" />
      </div>

      {/* /////////////////////////// */}
      <div className="footer-contact-container">

        {/* //////////////SK Precious//////////////////// */}
        <div>
          <h3>SK Precious</h3>

          <a href={addressLinkSKprecious} 
            target="_blank" rel="noreferrer" aria-label='Find out our Location on Google Maps'>
            <h3 className='link-header'>A</h3>
            <p className='link-content'>Plot no. 15-16, District Centre,</p>
            <p className='link-content'>Janakpuri, New Delhi,</p>
            <p className='link-content'>Delhi - 110058</p>
          </a>

          {!isSmallWindow && <br />}

          <a href={emailLinkSKprecious} aria-label='E-mail Us at skbyprecious@gmail.com'>
            <h3 className='link-header'>E</h3>
            <p className='link-content'>skbyprecious@gmail.com</p>
          </a>

          {!isSmallWindow && <br />}

          <a href={phoneLinkSKprecious} aria-label='Call us on +91 9871443777'>
          <h3 className='link-header'>P</h3>
            <p className='link-content'>+91 9871443777</p>
          </a>

          </div>

          {/* ///////////Precious Moments/////////// */}
          <div>
            <h3>Precious Moments</h3>
            <a href={addressLinkPreciousMoments} 
              target="_blank" rel="noreferrer" aria-label='Find out our Location on Google Maps'>
              <h3 className='link-header'>A</h3>
              <p className='link-content'>B-1/627, Najafgarh Rd, near Metro Pillar No. 571,</p>
              <p className='link-content'>Janakpuri, New Delhi,</p>
              <p className='link-content'>Delhi - 110058</p>
            </a>

            {!isSmallWindow && <br />}

            <a href={emailLinkPreciousMoments} aria-label='E-mail Us at skbyprecious@gmail.com'>
              <h3 className='link-header'>E</h3>
              <p className='link-content'>preciousbanquet@gmail.com</p>
            </a>

            {!isSmallWindow && <br />}

            <a href={phoneLinkPreciousMoments_1} aria-label='Call us on +91 9871443777'>
            <h3 className='link-header'>P</h3>
              <p className='link-content'>+91 9871502800</p>
            </a>

            <a href={phoneLinkPreciousMoments_2} aria-label='Call us on +91 9871443777'>
            {/* <h3 className='link-header'>P</h3> */}
              <p className='link-content'>+91 9871221172</p>
            </a>
          </div>
          
      </div>

      {/* /////////////////////////// */}

      <div className="footer-social-container">
          {isSmallWindow &&
          <>
          <div className="footer-social-icon-box"
            style={{marginBottom:"1rem"}}
          >
            <a href="https://instagram.com/skprecious_banquet" target="_blank" rel="noreferrer" 
              aria-label='Check us out on Instagram'
            > 
              <BiLogoInstagram className='footer-social-icon'/>
            </a>

            <ReactWhatsapp
                  className='whatsapp-chat-btn'
                  number={whatsappNumber}
                  aria-label='Whatsapp Us on +919871443777'
                  message=''
                  >

            <BiLogoWhatsapp className='footer-social-icon' />
            </ReactWhatsapp>
          </div>
          {/* <br/> */}
          </>  
          }

          <a href="/">
            <p className='link-content'>Home</p>
          </a>

          <a href="/venues">
            <p className='link-content'>Venues</p>
          </a>

          <a href="/food-menu">
            <p className='link-content'>Food Menu</p>
          </a>

          <a href="/contact-us">
            <p className='link-content'>Contact Us</p>
          </a>

          {!isSmallWindow && <br />}
          
          {!isSmallWindow &&
          <div className="footer-social-icon-box">
            <a href="https://instagram.com/skprecious_banquet" target="_blank" rel="noreferrer" 
              aria-label='Check us out on Instagram'
            > 
              <BiLogoInstagram className='footer-social-icon'/>
            </a>

            <ReactWhatsapp
                  className='whatsapp-chat-btn'
                  aria-label='Whatsapp Us on +919871443777'
                  number={whatsappNumber}
                  message=''
                  >

            <BiLogoWhatsapp className='footer-social-icon' />
            </ReactWhatsapp>
          </div>}
      </div>

      <div className="footer-right-reserved">
      © Precious Banquets 2024 - All rights reserved
      </div>

    </div>
  )
}

export default Footer