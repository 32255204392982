import React from 'react'

function NotFound() {
  return (
    <div className="content-container home-container">
        <div className="banner-section">
        
        <div className="banner-heading">
          <h1>404</h1>
          <p>
            Page Not Found
          </p>
          {/* <h2>Halls</h2> */}
          
          {/* <div className="banner-header-decoration"></div> */}
        </div>

      
      </div>
    </div>
  )
}

export default NotFound