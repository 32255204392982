import React from 'react'
import "./Loading.css"

function Loading() {
  // const coverImg_1 = "https://ik.imagekit.io/qljqw3tvn/PreciousBanquet/radiance_w4HPAR1l9.png"
  const coverImg_1 = "images/red_bg.webp"
  
  return (
    <>
    {/* <div className="loading"> */}

    <div className="loading-img-bg">
      <img src={coverImg_1} alt="" />
    </div>

    <div className='loading-overlay'>

        <div className="loading-content">
            <div className='loading-to-nav'>
                <div className="decoration-circle"></div>
                <h1>
                PRECIOUS BANQUETS
                </h1>
                <div className="decoration-circle"></div>
            </div>
        </div>

    </div>
    {/* </div> */}
    </>
  )
}

export default Loading