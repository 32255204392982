import { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Footer from './components/Footer/Footer';
import Hall from './pages/Hall/Hall';
import Contact from './pages/Contact/Contact';
import FoodAndService from './pages/FoodAndServices/FoodAndService';
import { useState } from 'react';
import NotFound from './pages/NotFound/NotFound';
import Floor from './pages/Hall/Floor';
import HallsCollection from './pages/Hall/Venues';
import Venues from './pages/Hall/Venues';


function App() {
  const [loaderFlag, setLoaderFlag] = useState(true); 

  return (
    <>
    <AnimatePresence>
      
    <Router>
      {/* <div ref={appRef} className='App'>
      
      <div className='scroll' ref={scrollContainer}> */}

      <div className="container" >
        <Navbar loaderFlag={loaderFlag}/>
        
        <Routes>

          <Route path='/' element={<Home passLoaderFlag={setLoaderFlag} />} />
          <Route path='/venues' element={<Venues />} />
          <Route path='/venues/:halls' element={<Hall />} />
          <Route path='/venues/:halls/:floor' element={<Floor />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/food-menu' element={<FoodAndService />} />

          <Route path='*' element={<NotFound />} />
        
        </Routes>

        <Footer />
      </div>
        {/* </div> 

      </div> */}
      
    </Router>
    
    </AnimatePresence>
    </>
  );
}

export default App;
