import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import "./Contact.css"
import ReactWhatsapp from 'react-whatsapp';
import Form from '../../components/Form/Form';
// import { useMediaQuery } from 'react-responsive';

function Contact() {
  // const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' })

  const addressLinkSKprecious = "https://maps.app.goo.gl/sjZHRJWitTEyMzo86"
  const addressLinkPreciousMoments = "https://maps.app.goo.gl/tn9qeCRwzz9M8p536"
  
  const emailLinkSKprecious = "mailto:skbyprecious@gmail.com"
  const emailLinkPreciousMoments = "mailto:preciousbanquet@gmail.com"
  
  const phoneLinkSKprecious = "tel:+919871443777"
  const phoneLinkPreciousMoments_1 = "tel:+919871502800"
  const phoneLinkPreciousMoments_2 = "tel:+919871221172"

  const whatsappNumber = "+919871443777"

  ///////Address Ref/////////////
  const [addressRef_SKprecious, addressInView_SKprecious] = useInView()
  const addressAnim_SKprecious = useAnimation();

  const [addressRef_PreciousMoments, addressInView_PreciousMoments] = useInView()
  const addressAnim_PreciousMoments = useAnimation();

  ///////Email Ref///////////////
  const [emailRef_SKprecious, emailInView_SKprecious] = useInView()
  const emailAnim_SKprecious = useAnimation();

  const [emailRef_PreciousMoments, emailInView_PreciousMoments] = useInView()
  const emailAnim_PreciousMoments = useAnimation();

  //////Phone Ref///////////////
  const [phoneRef_SKprecious, phoneInView_SKprecious] = useInView()
  const phoneAnim_SKprecious = useAnimation();

  const [phoneRef_PreciousMoments, phoneInView_PreciousMoments] = useInView()
  const phoneAnim_PreciousMoments = useAnimation();

  /////Whatsapp Ref/////////////
  const [whatsappRef, whatsappInView] = useInView()
  const whatsappAnim = useAnimation();

  useEffect(()=>{
    if (addressInView_SKprecious) {
      addressAnim_SKprecious.start("visible")
    }
    if(addressInView_PreciousMoments){
      addressAnim_PreciousMoments.start("visible")
    }

    if(emailInView_SKprecious){
      emailAnim_SKprecious.start("visible")
    }
    if(emailInView_PreciousMoments){
      emailAnim_PreciousMoments.start("visible")
    }

    if(phoneInView_SKprecious){
      phoneAnim_SKprecious.start("visible")
    }
    if(phoneInView_PreciousMoments){
      phoneAnim_PreciousMoments.start("visible")
    }
    if(whatsappInView){
      whatsappAnim.start("visible")
    }
  },[addressInView_SKprecious, addressInView_PreciousMoments,
    emailInView_SKprecious, emailInView_PreciousMoments, 
    phoneInView_SKprecious, phoneInView_PreciousMoments, 
    whatsappInView])

  return (
    <div className="content-container home-container">
      <div className="banner-section">
        
        <motion.div className="banner-heading banner-heading-no-underline"
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1, ease:[.6,.01,.56,.94]}}
        >
          <h1>Contact Us</h1>
          {/* <p style={{fontStyle:"italic",fontSize:"1rem"}}>Your day as per Your taste</p> */}
          
        </motion.div>

      
      </div>

      <div className="contact-venue-banner-container"
      >SK Precious</div>

      {/* Box */}
      <div className="section-grid-container contact-grid-container"
        ref={addressRef_SKprecious}
      >
          <h3 className='link-header contact-link-header'>
            Address
          </h3>
          
          {/* Link Element */}
          <div className="contact-content">
            
            <motion.a className="link-tag"
              href={addressLinkSKprecious} 
              target="_blank" rel="noreferrer"
              aria-label='Find out our Location on Google Maps'
              variants={{
                hidden:{y:"4rem", rotate:"0deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={addressAnim_SKprecious}
              >
              <p className='link-content contact-link-content'>
                Plot no. 15-16, District Centre,
              </p>
              <p className='link-content contact-link-content'>
                Janakpuri, New Delhi,
              </p>
              <p className='link-content contact-link-content'>
                Delhi - 110058
              </p>

            </motion.a>

          </div>
      </div>

      <div className="section-grid-container contact-grid-container"
        ref={emailRef_SKprecious}
      >
          <h3 className='link-header contact-link-header'>
            Email
          </h3>
           
           {/* Link Element */}
          <div className="contact-content">

            <motion.a className='link-tag' 
              href={emailLinkSKprecious}
              aria-label='E-mail to us at skbyprecious@gmail.com'
              variants={{
                hidden:{y:"4rem", rotate:"0deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={emailAnim_SKprecious}
              >
              <p className='link-content contact-link-content'>skbyprecious@gmail.com</p>  
            </motion.a>
          
          </div>
      
      </div>

      <div className="section-grid-container contact-grid-container"
        ref={phoneRef_SKprecious}
      >
          <h3 className='link-header contact-link-header'>
            Phone
          </h3>

          {/* Link Element */}
          <div className="contact-content">
            <motion.a 
              className='link-tag' 
              href={phoneLinkSKprecious}
              aria-label='Call us on +91 9871443777'
              variants={{
                hidden:{y:"4rem", rotate:"0deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={phoneAnim_SKprecious}
            >
              <p className='link-content contact-link-content'>+91 9871443777</p>
            </motion.a>
          
          </div>
      </div>

      <div className="section-grid-container contact-grid-container"
      style={{borderBottom:"1px solid var(--textColour"}}
        ref={whatsappRef}
      >
          <h3 className='link-header contact-link-header'>
            Whatsapp
          </h3>
            {/* Link Element */}
          <div className="contact-content">

            <motion.a className='link-tag' 
              href="tel:+9198715 02800"
              aria-label='Whatsapp us on +9198715 02800'
              variants={{
                hidden:{y:"4rem", rotate:"0deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={whatsappAnim}
              >
                <ReactWhatsapp 
                  className='whatsapp-chat-btn'
                  number={whatsappNumber}
                  message=''
                  >
              <p className='link-content contact-link-content'>
                  Whatsapp Chat 
              </p>
              </ReactWhatsapp>
              {/* <BiLogoWhatsapp className='contact-social-icon' /> */}
            </motion.a>
          
          </div>
      </div>

{/* /////////////////////////////Precious Moments//////////////////////////////////////// */}
      <div className=""
        style={{width:"100%",height:"10vh"}}
      ></div>
      
        <div className="contact-venue-banner-container"
      >Precious Moments</div>

      {/* Box */}
      <div className="section-grid-container contact-grid-container"
        ref={addressRef_PreciousMoments}
      >
          <h3 className='link-header contact-link-header'>
            Address
          </h3>
          
          {/* Link Element */}
          <div className="contact-content">
            
            <motion.a className="link-tag"
              href={addressLinkPreciousMoments} 
              target="_blank" rel="noreferrer"
              aria-label='Find out our Location on Google Maps'
              variants={{
                hidden:{y:"4rem", rotate:"0deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={addressAnim_PreciousMoments}
              >
              <p className='link-content contact-link-content'>
                B-1/627, Najafgarh Rd, near Metro Pillar No. 571,
              </p>
              <p className='link-content contact-link-content'>
                Janakpuri, New Delhi,
              </p>
              <p className='link-content contact-link-content'>
                Delhi - 110058
              </p>

            </motion.a>

          </div>
      </div>

      <div className="section-grid-container contact-grid-container"
        ref={emailRef_PreciousMoments}
      >
          <h3 className='link-header contact-link-header'>
            Email
          </h3>
           
           {/* Link Element */}
          <div className="contact-content">

            <motion.a className='link-tag' 
              href={emailLinkPreciousMoments}
              aria-label='E-mail to us at preciousbanquet@gmail.com'
              variants={{
                hidden:{y:"4rem", rotate:"0deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={emailAnim_PreciousMoments}
              >
              <p className='link-content contact-link-content'>preciousbanquet@gmail.com</p>  
            </motion.a>
          
          </div>
      
      </div>

      <div className="section-grid-container contact-grid-container"
        ref={phoneRef_PreciousMoments}
        style={{borderBottom:"1px solid var(--textColour"}}
      >
          <h3 className='link-header contact-link-header'>
            Phone
          </h3>

          {/* Link Element */}
          <div className="contact-content">
            <motion.a 
              className='link-tag' 
              href={phoneLinkPreciousMoments_1}
              aria-label='Call us on +91 9871502800'
              variants={{
                hidden:{y:"4rem", rotate:"0deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={phoneAnim_PreciousMoments}
            >
              <p className='link-content contact-link-content'>+91 9871502800</p>
            </motion.a>
            
            <motion.a 
              className='link-tag' 
              href={phoneLinkPreciousMoments_2}
              aria-label='Call us on +91 9871221172'
              variants={{
                hidden:{y:"4rem", rotate:"0deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={phoneAnim_PreciousMoments}
            >
              <p className='link-content contact-link-content'>+91 9871221172</p>
            </motion.a>
          
          </div>
      </div>

      {/* <div className="section-grid-container contact-grid-container"
      style={{borderBottom:"1px solid var(--textColour"}}
      >
          <h3 className='link-header contact-link-header'>
            Whatsapp
          </h3>
          <div className="contact-content">

            <motion.a className='link-tag' 
              href="tel:+9198715 02800"
              aria-label='Whatsapp us on +9198715 02800'
              variants={{
                hidden:{y:"4rem", rotate:"0deg",opacity:0},
                visible:{y:0,rotate:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={whatsappAnim}
              >
                <ReactWhatsapp 
                  className='whatsapp-chat-btn'
                  number={whatsappNumber}
                  message=''
                  >
              <p className='link-content contact-link-content'>
                  Whatsapp Chat 
              </p>
              </ReactWhatsapp>
            </motion.a>
          
          </div>
      </div> */}



      <div className=""
        style={{width:"100%",height:"20vh"}}
      ></div>
      <Form />

    </div>
  )
}

export default Contact