import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion"

import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'

import "./Hall.css"
import floorList from '../../components/Lists/FloorList'
import NotFound from '../NotFound/NotFound'
import Form from '../../components/Form/Form'

function Hall() {
  const isSmallWindow = useMediaQuery({ query: '(max-width: 1000px)' })

  const params = useParams()

  const filteredfloorList = floorList.filter((x)=>x.venueSlug===params.halls)
  
    // useEffect(()=>{
    //     console.log(`filFloorList:${JSON.stringify(filteredfloorList,null,4)}`)
    // },[])

  return (
    <>
    {filteredfloorList.length===0?
    <NotFound />
    :
    <div className="content-container home-container">
        {/* Heading */}
        <div className="banner-section">
        
        <motion.div className="banner-heading"
            initial={{opacity:0}}
            animate={{opacity:1}}
            transition={{duration:1, ease:[.6,.01,.56,.94]}}
        >
            <h1>{filteredfloorList[0].venueName}</h1>
            <p>
            {
                params.halls===`sk-precious`?
                `A modern haven of elegance and style.`
                :
                `Where affordability meets sophistication.`
            }
            </p>
        </motion.div>

        </div>

        <div className="half-grid-container grid-half-container">

            {filteredfloorList.map((floorItem,index)=>{
                return(index%2===0?
                // Even
                <div className='grid-full-box' key={index}>

                <div className='hall-colour-bg left-bg'
                >
                  <h1>0{floorItem.floorNum} .</h1>
                  <h1>{floorItem.hallName}</h1>
                </div>

                <div className="hall-card-text">
                <div className="hall-text">
                    <p style={{fontSize:"2rem"}}>{floorItem.floor}</p>
                    <br />
                    <p>{floorItem.floorCardText}</p>
                </div>
                </div>

                <div className="hall-card-img left-card-img"> 
                <a href={`/venues/${floorItem.venueSlug}/${floorItem.slug}`}
                  aria-label={`Know more about ${floorItem.hallName}`}
                >
                    
                <motion.img 
                    className='card-img'
                    initial={{opacity:1,y:"10%"}}
                    animate={{opacity:1,y:0}}
                    transition={{duration:0.6,ease:[0.6,0.01,0.05,0.95]}}

                    src={floorItem.coverImg} alt="" />
                    <div className="hall-card-img-text"
                    >
                    EXPLORE
                    </div>
                </a>
                </div>

            </div>
            :
            
            // Odd
            <div className='grid-full-box' key={index}>

                {!isSmallWindow && 
                <div className={`hall-card-text right-card-text`} >
                    <div className="hall-text right-hall-text">
                    <p style={{fontSize:"2rem"}}>{floorItem.floor}</p>
                    <br />
                    <p>{floorItem.floorCardText}</p>
                    </div>
                </div>}

                <div className={`hall-colour-bg ${isSmallWindow?"left-bg":"right-bg"}`}
                >
                    <h1>0{floorItem.floorNum} .</h1>
                    <h1>{floorItem.hallName}</h1>
                    {/* <h1>{hallName}</h1> */}
                </div>

                {isSmallWindow && 
                <div className={`hall-card-text`} >
                    <div className="hall-text right-hall-text">
                    <p style={{fontSize:"2rem"}}>{floorItem.floor}</p>
                    <br />
                    <p>{floorItem.floorCardText}</p>
                    </div>
                </div>}
                

                <div className="hall-card-img right-card-img">

                    <a href={`/venues/${floorItem.venueSlug}/${floorItem.slug}`}
                        aria-label={`Know more about ${floorItem.hallName}`}
                    >
                    
                    <motion.img 
                    className='card-img'
                    initial={{opacity:1,y:"10%"}}
                    animate={{opacity:1,y:0}}
                    transition={{duration:0.6,ease:[0.6,0.01,0.05,0.95]}}
                    
                    src={floorItem.coverImg} alt="" />
                    <div className="hall-card-img-text"
                    >
                        EXPLORE
                    </div>
                    
                    </a>
            </div>

            </div>
            )})
            }

            
        </div>
    </div>
    }
    <div className=""
        style={{width:"100%",height:"20vh"}}
      ></div>

      <Form />
    </>
  )
}

export default Hall