import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion"
import { useMediaQuery } from 'react-responsive';

import "./VenueCard.css"

function VenueCard({cardNum, venueName, venueImgSrc,
  venueSlug="sk-precious",venueText}) {
    const isSmallWindow = useMediaQuery({ query: '(max-width: 1200px)' })

    const [hoverBool, setHoverBool] = useState(false)

    const handleHoverStart =()=>{
        setHoverBool(true)
    }

    const handleHoverEnd =()=>{
        setHoverBool(false)
    }

    // useEffect(()=>{
    //     console.log(`hoverBool:${hoverBool}`)
    // },[hoverBool])

  return (
    <div className='grid-full-box'
      style={{minHeight:"100vh"}}
    >
        <div className={`hall-colour-bg left-bg`}
          style={(!isSmallWindow && hoverBool)?{width:`200%`}:{}}
        >
          <h1>{cardNum}</h1>
          <h1 style={isSmallWindow?{fontSize:`3rem`}:{}}
          >{venueName}</h1>
          <br />

          {(isSmallWindow || hoverBool) &&
            <div className='venue-para-container'>
              {venueText.text.map((line,index)=>{
                return (
                  <motion.p className='venue-para'
                    initial={{opacity:0,x:"1%"}}
                    animate={{opacity:1,x:0}}
                    transition={{duration:1, delay:0.6, ease:[0.6,0.01,0.05,0.95]}}
                    key={index}
                  >
                    {line}
                  </motion.p>
                )
              })}

              <br />
            
              <motion.p className='venue-para'
              initial={{opacity:0,x:"1%"}}
              animate={{opacity:1,x:0}}
              transition={{duration:1,delay:0.6, ease:[0.6,0.01,0.05,0.95]}}
              >
              <span style={{fontStyle:"italic"}}>{venueText.moto}</span>
              </motion.p>
            
            </div>
        }

        </div>

        <div className="venue-card-img"> 
            <a href={`/venues/${venueSlug}`} aria-label='Know more about Radiance Floor'>
              
            <motion.img 
              className='venue-img'
              initial={{opacity:1,y:"10%"}}
              animate={{opacity:1,y:0}}
              transition={{duration:0.6,ease:[0.6,0.01,0.05,0.95]}}
              
              onMouseEnter={()=>handleHoverStart()} 
              onMouseLeave={()=>handleHoverEnd()}

              src={venueImgSrc} alt="" />
              <div className="hall-card-img-text"
                onMouseEnter={()=>handleHoverStart()} 
                onMouseLeave={()=>handleHoverEnd()}
              >
                EXPLORE
              </div>
            </a>
          </div>
      
    </div>
  )
}

export default VenueCard