import React, { useEffect } from 'react'
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { useParams} from 'react-router-dom'
import floorList from '../../components/Lists/FloorList'
import NotFound from '../NotFound/NotFound'
import Gallery from '../../components/Gallery/Gallery';
import Form from '../../components/Form/Form';

function Floor() {
    const params = useParams()
    const floorData = floorList?.filter((floorItem) => 
    floorItem.slug===params.floor)[0]  
    
    const [ammenitiesRef, ammenitiesInView] = useInView()
    const ammenitiesAnim = useAnimation();

    const [floorPoemRef, floorPoemInView] = useInView()
    const floorPoemAnim = useAnimation();
    
    useEffect(()=>{
      if(floorPoemInView){
        floorPoemAnim.start("visible")
      }    
      if(ammenitiesInView){
        ammenitiesAnim.start("visible")
      }
    },[ammenitiesInView, floorPoemInView])

  return (
    <>
    {typeof floorData==="undefined"?
    <NotFound />
     :
     <>

    <div className="content-container home-container">
      {/* Heading */}
     <motion.div className="banner-heading"
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1, ease:[.6,.01,.56,.94]}}>
        
        <div className="banner-heading">
          <h1>{floorData.hallName}</h1>
          <p>
            {floorData.floor}
          </p>
          {/* <h2>Halls</h2> */}
          
          {/* <div className="banner-header-decoration"></div> */}
        </div>

      
      </motion.div>

      <div className="full-img-container"
        style={{height:"100vh"}} 
      >
        <motion.img 
          initial={{opacity:0,y:"10%"}}
          animate={{opacity:1,y:0}}
          transition={{duration:0.6,ease:[0.6,0.01,0.05,0.95]}}
          src={floorData.coverImg} alt=""
          />
      </div>
    
    {floorData.ammenitiesList.length===0 &&
      <div className=""
        style={{height:"10vh"}}
      ></div>
    }

    {floorData.ammenitiesList.length>0 &&
    <div className="half-grid-container grid-half-container"
        // style={{border:"0.5px solid var(--textColour"}}
      >
        <div className='grid-full-box'
          style={{minHeight:`80vh`}}
        >

          <motion.div className='hall-colour-bg left-bg'
            ref={floorPoemRef}
            variants={{
              hidden:{y:"3rem",opacity:0},
              visible:{y:0,opacity:1,transition:{duration:0.6,delay:0,ease:[0.6,0.01,0.56,0.93]}}
            }}
            initial="hidden"
            animate={floorPoemAnim}
          >
            <br />
            {floorData.floorPoem?.map((poemLine,index)=>{
               return (
               <h2 key={index}
                style={{fontSize:"1.5rem"}}
               >
                {poemLine}
               </h2>
               )
            })}
          </motion.div>

          <div className="hall-card-text">
            <motion.div className="hall-text"
              ref={ammenitiesRef}
              variants={{
                hidden:{y:"3rem",opacity:0},
                visible:{y:0,opacity:1,transition:{duration:1, ease:[0.6,0.01,0.56,0.93]}}
              }}
              initial="hidden"
              animate={ammenitiesAnim}
            >
              <p style={{fontSize:"3rem", fontWeight:"300"}}>Amenities</p>
              <br />
              {floorData.ammenitiesList?.map((info,index)=>{
                return (
                  <p style={{fontSize:"1.5rem", fontWeight:"300"}}
                    key={index}
                  >
                    {info}
                  </p>
                )
              })}
              {/* <p style={{fontSize:"1.5rem", fontWeight:"300"}}>100- 250 guests</p>
              <p style={{fontSize:"1.5rem", fontWeight:"300"}}>DJ with LED screens</p>
              <p style={{fontSize:"1.5rem", fontWeight:"300"}}>Outdoor covered catering</p> */}
            </motion.div>
          </div>

          {/* <div className="hall-card-img left-card-img"> 
            <a href="/halls/radiance">
              
            <motion.img 
              className='card-img'
              initial={{opacity:1,y:"10%"}}
              animate={{opacity:1,y:0}}
              transition={{duration:0.6,ease:[0.6,0.01,0.05,0.95]}}
              src={coverImg_1} alt="" />
              <div className="hall-card-img-text">
                EXPLORE
              </div>
            </a>
          </div> */}

        </div>
      </div>}

      <Gallery imageList={floorData.imgLinkList} currentIndex={0}/>

    </div>
    </>
    }
    <div className=""
        style={{width:"100%",height:"20vh"}}
      ></div>

      <Form />
    </>
  )
}

export default Floor