import React from 'react'
import { motion, useAnimation } from "framer-motion"
import VenueCard from '../../components/VenueCard/VenueCard'
import Form from '../../components/Form/Form'

function Venues() {
  const venue1_cover_img = "./images/SK_Precious.jpg"
  const venue2_cover_img = "./images/preciousMoments_cover.jpeg"

  const venue1_slug = "sk-precious"
  const venue2_slug = "precious-moments"

  const venue1_name = "SK Precious"
  const venue2_name = "Precious Moments"

  return (
    <div className="content-container home-container">
        {/* Heading */}
        <div className="banner-section">
        
        <motion.div className="banner-heading"
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1, ease:[.6,.01,.56,.94]}}
        >
          <h1>Our Venues</h1>
          {/* <p>
            Choose from our locations
          </p> */}
          {/* <p></p> */}
          {/* <h2>Halls</h2> */}
          
          {/* <div className="banner-header-decoration"></div> */}
        </motion.div>      
      </div>

      <div className="half-grid-container grid-half-container">
        
        <div className="full-row-container" 
          style={{
              // border:`1px solid red`,
              display:`flex`, 
              justifyContent:`center`, 
              alignItems:`flex-start`
            }}
        >
          
          <section className='medium-text-section description-text-section' 
            style={{textAlign:`center`
            }}
          >
              <p>Our halls are designed to provide a contemporary yet elegant backdrop for your events.</p>
              <br />
              <p>State-of-the-art facilities, ambient lighting, and a versatile layout make our halls the perfect canvas for your vision. We aim to be recognized not just as a local gem but among the world's best wedding venues, and our commitment to staying on the cutting edge of event trends is reflected in our modern halls.</p>
            </section>
          </div>
        {/* //////////////////////////// */}
        {/* <div className='grid-full-box'
        >
        
          <div className='hall-colour-bg left-bg'>
            <h1>SK Precious</h1>
          </div>

          <div className="hall-card-text">
            <div className="hall-text">
              <p style={{fontSize:"2rem"}}>Ground Floor</p>
              <br />
              <p>A sophisticated modern approach to small events. Radiance Hall is the ideal mix of indoor and outdoor catering with a view</p>
            </div>
          </div>
          
        </div> */}
        <VenueCard cardNum="01 ."
          venueName={venue1_name}
          venueSlug= {venue1_slug}
          venueImgSrc= {venue1_cover_img}
          venueText={{text:[`Recently renovated, our halls boast a capacity for 700 guests, blending opulence with comfort. The rooftop, with open space and panoramic views, adds a unique touch to your special events.`,
                            `At SK Precious, we redefine luxury gatherings, offering a contemporary venue where every detail is tailored to ensure your celebration is extraordinary and unforgettable.`],
                      moto:`A modern haven of elegance and style.`}}
        />
        <VenueCard cardNum="02 ."
          venueName={venue2_name}
          venueSlug={venue2_slug}
          venueImgSrc= {venue2_cover_img}
          venueText={{text:[`With modern interiors, our halls, accommodating up to 300 guests, are designed for intimate gatherings. Backed by over 12 years of experienced management, we ensure seamless events.`
                          ,`Ample parking, a newly elevated exterior, and contemporary interiors make Precious Moments Banquet the perfect setting for creating cherished memories in a stylish and budget-friendly environment.`],
                    moto:`Where affordability meets sophistication.`}}
        />

      {/* //////////////////////////////// */}
      {/* <div className='grid-full-box'>

      </div> */}

    </div>

    {/* <div className=""
        style={{width:"100%",height:"20vh"}}
      ></div> */}

      <Form/>
    </div>
  )
}

export default Venues